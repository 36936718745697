//Amend container widths
$custom-container-max-widths: (
  sm: 100%,
  md: 100%,
  lg: 100%,
);

// Amend position values
$custom-position-values: (
  25: 25%,
  n25: -25%,
  n50: -50%,
  75: 75%,
  n75: -75%,
);

// Amend spacers
$custom-spacers: (
  6: $spacer * 3.5,
  7: $spacer * 4,
  8: $spacer * 5,
  9: $spacer * 6,
  10: $spacer * 7,
);

// Merge the maps
$container-max-widths: map-merge(
  $container-max-widths,
  $custom-container-max-widths
);

$position-values: map-merge($position-values, $custom-position-values);

$spacers: map-merge($spacers, $custom-spacers);
