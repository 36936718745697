.footer-heading {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0.25em;
    opacity: .5;
    color: $white;
    margin-bottom: 1.5rem;
}

.footer-nav {
    li {
        margin-bottom: .35rem;
    }

    a {
        color: rgba(255,255,255, .85);
        transition: color ease-in .3s;
        font-size: 1.025rem;

        &:hover {
            text-decoration: none;
            color: $primary;
        }
    }
}